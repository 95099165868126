import type { Request } from 'express'
import { pick } from 'accept-language-parser'

export type Language = 'de' | 'en' | 'es' | 'nl' | 'fr' | 'it'

export const DEFAULT_LANGUAGE: Language = 'en'
export const SUPPORTED_LANGUAGES: Set<Language> = new Set(['de', 'en', 'es', 'nl', 'fr', 'it'] as Array<Language>)

export function isSupportedLanguage(language: string): language is Language {
  return SUPPORTED_LANGUAGES.has(language as Language)
}

// en-US => en; DE-AT => de; DE => de; de => de
export function getLanguageFromLanguageWithLocale(language: string): Language {
  // TODO: Add checking if this really is a locale or just a string
  return language.toLowerCase().split('-')[0] as Language
}

/**
 * @deprecated Use getLanguageFromAcceptLanguageHeader instead
 * @param req express request
 */
export function getLanguageFromRequest(req: Request): Language | undefined {
  return getSupportLanguageFromAcceptLanguageHeader(req.headers['accept-language'] || '')
}

/**
 * Returns the language from the accept-language header; undefined if no language is found or none of the found languages is supported
 * @param acceptLanguageHeader
 */
export function getSupportLanguageFromAcceptLanguageHeader(acceptLanguageHeader: string): Language | undefined {
  const matchedLanguage = pick([...SUPPORTED_LANGUAGES], acceptLanguageHeader, { loose: true })
  return matchedLanguage ? getLanguageFromLanguageWithLocale(matchedLanguage) : undefined
}

/**
 * Returns the language from the accept-language header; DEFAULT if no language is found or none of the found languages is supported
 * @param acceptLanguageHeader
 */
export function getSupportedLanguageOrDefaultFromAcceptLanguageHeader(
  acceptLanguageHeader: string | undefined,
): Language {
  return (acceptLanguageHeader && getSupportLanguageFromAcceptLanguageHeader(acceptLanguageHeader)) || DEFAULT_LANGUAGE
}
