/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddMatch": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "AddOnField": [
      "NumberAddOnField",
      "SelectAddOnField",
      "TextAddOnField"
    ],
    "AddParticipantOnMatchV2": [
      "ExpectedErrors",
      "MatchParticipant"
    ],
    "AddToWaitingListResponse": [
      "ExpectedErrors",
      "WaitingList"
    ],
    "AssignedBookingProduct": [
      "AssignedCard",
      "AssignedMembership"
    ],
    "AssignedMembershipResponse": [
      "AssignedMembership",
      "ExpectedErrors"
    ],
    "AssignedProduct": [
      "AssignedCard",
      "AssignedMembership",
      "AssignedRecording",
      "AssignedVoucher",
      "GenericAssignedProduct"
    ],
    "AssignedProductInterface": [
      "AssignedCard",
      "AssignedMembership",
      "AssignedVoucher"
    ],
    "AssignedProductsForUserProfileType": [
      "AssignedCard",
      "AssignedMembership"
    ],
    "AuthResponse": [
      "AuthResult",
      "ExpectedErrors"
    ],
    "BookableItem": [
      "CourtBookableItem",
      "EventBookableItem"
    ],
    "Booking": [
      "CourtBooking",
      "EventBooking"
    ],
    "BookingInterface": [
      "CourtBooking",
      "EventBooking",
      "PublicCourtBooking"
    ],
    "BudgetLimit": [
      "Limited",
      "Unlimited"
    ],
    "CancelMatch": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "CartItem": [
      "BookableItemCartItem",
      "ProductCartItem"
    ],
    "CartItemInterface": [
      "BookableItemCartItem",
      "ProductCartItem"
    ],
    "CartResponse": [
      "Cart",
      "ExpectedErrors"
    ],
    "CheapestProduct": [
      "Card",
      "Membership"
    ],
    "CheckInResponse": [
      "ExpectedErrors",
      "SelfCheckIn"
    ],
    "ConfirmWithOption": [
      "AssignedCard",
      "AssignedMembership"
    ],
    "CreatedBallsportMatch": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "DeleteMatch": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "DiscoverContext": [
      "DiscoverContextForCity",
      "DiscoverContextForCityAndSport",
      "DiscoverMatchContext"
    ],
    "DiscoverVenueContext": [
      "DiscoverContextForCity",
      "DiscoverContextForCityAndSport"
    ],
    "FamilyResponse": [
      "ExpectedErrors",
      "Family"
    ],
    "InventorySearchResult": [
      "SportSearchResult",
      "VenueSearchResult"
    ],
    "InvitedBy": [
      "User",
      "Venue"
    ],
    "JoinMatchV2": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "LeaveMatchV2": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "Match": [
      "BallsportMatch"
    ],
    "MatchInterface": [
      "BallsportMatch"
    ],
    "MatchOrganizerInterface": [
      "PrivateMatchOrganizer",
      "PublicMatchOrganizer"
    ],
    "MatchOrganizerType": [
      "PrivateMatchOrganizer",
      "PublicMatchOrganizer"
    ],
    "MatchParticipantIdentity": [
      "PublicUser",
      "User"
    ],
    "MatchParticipantUserType": [
      "PublicUserV2",
      "User"
    ],
    "MatchVisibilityValidation": [
      "PrivateMatchVisibilityValidation",
      "PublicMatchVisibilityValidation"
    ],
    "MatchVisibilityValidationInterface": [
      "PrivateMatchVisibilityValidation",
      "PublicMatchVisibilityValidation"
    ],
    "Notification": [
      "BaseNotification"
    ],
    "NotificationIcon": [
      "NotificationIconImage",
      "NotificationIconUser"
    ],
    "NotificationInterface": [
      "BaseNotification"
    ],
    "NotificationLink": [
      "NotificationLinkEvent",
      "NotificationLinkEventSession",
      "NotificationLinkMatch",
      "NotificationLinkUrl",
      "NotificationLinkUser",
      "NotificationLinkVenue"
    ],
    "OrderItem": [
      "AssignedProductOrderItem",
      "BookingOrderItem"
    ],
    "OrderItemInterface": [
      "AssignedProductOrderItem",
      "BookingOrderItem"
    ],
    "OrderResponse": [
      "ExpectedErrors",
      "Order"
    ],
    "PageLink": [
      "SportInCityPageLink"
    ],
    "Participation": [
      "EventSessionParticipation"
    ],
    "ParticipationInterface": [
      "BallsportMatch",
      "CourtBooking",
      "EventSessionParticipation",
      "PublicCourtBooking",
      "PublicEventSessionParticipation"
    ],
    "PaymentResponseType": [
      "ExpectedErrors",
      "Payment"
    ],
    "PriceTableItem": [
      "PriceTableItemAddon",
      "PriceTableItemBasic",
      "PriceTableItemMembership",
      "PriceTableItemVoucher"
    ],
    "PriceTableItemInterface": [
      "PriceTableItemAddon",
      "PriceTableItemBasic",
      "PriceTableItemBudget",
      "PriceTableItemMembership",
      "PriceTableItemSpecialPrice",
      "PriceTableItemVoucher"
    ],
    "PriceTableItemV2": [
      "PriceTableItemAddon",
      "PriceTableItemBasic",
      "PriceTableItemBudget",
      "PriceTableItemMembership",
      "PriceTableItemVoucher"
    ],
    "PriceTableItemV3": [
      "PriceTableItemAddon",
      "PriceTableItemBasic",
      "PriceTableItemBudget",
      "PriceTableItemMembership",
      "PriceTableItemSpecialPrice",
      "PriceTableItemVoucher"
    ],
    "PrivateFeedbackResponse": [
      "ExpectedErrors",
      "PrivateFeedback"
    ],
    "ProductInterface": [
      "Card",
      "Membership",
      "Ticket"
    ],
    "PublicParticipation": [
      "PublicCourtBooking",
      "PublicEventSessionParticipation"
    ],
    "PublicParticipationV2": [
      "BallsportMatch",
      "PublicCourtBooking",
      "PublicEventSessionParticipation"
    ],
    "PurchaseOption": [
      "Card",
      "Membership",
      "SinglePurchase",
      "Ticket"
    ],
    "PurchasedWith": [
      "SinglePurchase",
      "Ticket"
    ],
    "RecordingValidity": [
      "TimePeriod",
      "TimeRange"
    ],
    "RemoveFromWaitingListResponse": [
      "ExpectedErrors",
      "WaitingList"
    ],
    "RemoveParticipantsFromMatchV2": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "RequestVerificationResponse": [
      "ExpectedErrors",
      "RequestVerificationResult"
    ],
    "ResetPasswordResponse": [
      "ExpectedErrors",
      "ResetPasswordResult"
    ],
    "SearchResult": [
      "CitySearchResult",
      "SportSearchResult",
      "VenueSearchResult"
    ],
    "SettlementOption": [
      "AssignedCard",
      "AssignedMembership",
      "Card",
      "GenericAssignedProduct",
      "Membership",
      "SinglePurchase",
      "Ticket"
    ],
    "ShopProduct": [
      "Card",
      "Membership",
      "Recording",
      "Voucher"
    ],
    "SocialInterface": [
      "OpenGraph",
      "Twitter"
    ],
    "SpotLimit": [
      "LimitedSpots",
      "UnlimitedSpots"
    ],
    "StoredPaymentMethod": [
      "StoredBankAccount",
      "StoredCreditCard"
    ],
    "StoredPaymentMethodInterface": [
      "StoredBankAccount",
      "StoredCreditCard"
    ],
    "StoredPaymentMethodResponse": [
      "ExpectedErrors",
      "StoredBankAccount",
      "StoredCreditCard"
    ],
    "UpdateMatch": [
      "BallsportMatch",
      "ExpectedErrors"
    ],
    "UserInterface": [
      "PrivateMatchOrganizer",
      "PublicMatchOrganizer",
      "PublicUserV2",
      "User"
    ],
    "UserResponseType": [
      "ExpectedErrors",
      "User"
    ]
  }
};
      export default result;
    